@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");


$font-size: 1.15rem; // This is 18.4px in browser

$navheight: 56px;



$theme-colors: (
  "primary": blueviolet,
  "secondary": orangered,
);


$weatherColors: (
  blowing_dust: #a2a2a2,
  blowing_sand: #d2b48c,
  blowing_snow: #1376ad,
  drizzle: #6f9faf,
  fog: #b0b0b0,
  freezing_fog: #bfbfbf,
  freezing_drizzle: #b0c4de,
  freezing_rain: #c49bc9,
  freezing_spray: #add8e6,
  frost: #e0ffff,
  hail: #b3c6ff,
  haze: #bebeebcb,
  ice_crystals: #d8bfd8,
  ice_fog: #e0ffff,
  rain: #494a66,
  rain_showers: #6272a8,
  sleet: #df6e2b,
  smoke: #d5af76c2,
  "snow": #b300ff,
  snow_showers: #7700ff,
  thunderstorms: #ffd500,
  volcanic_ash: #a9a9a9,
  water_spouts: #1e90ff,
);

:export {
  navheight: $navheight;
  fcolor: #f9f833;
  dcolor: #eaf32c;
  dpluscolor: #daef26;
  cminuscolor: #bbe51d;
  ccolor: #abe019;
  cpluscolor: #9bdb17;
  bminuscolor: #8ad616;
  bcolor: #79d117;
  bpluscolor: #67cb18;
  aminuscolor: #53c61a;
  acolor: #3ac11d;
  apluscolor: #12bb20;

}

:export {
  @each $name, $color in $weatherColors {
    #{$name}: $color
  }
}


html,
body {
  font-family: sans-serif;
  font-size: $font-size;
  overflow-x: hidden;
  scroll-behavior: unset !important;
  
}

.title {
  font-family: "Bebas Neue", sans;
}

#mainNavbar {
  touch-action: none;
}

.mobile-title {
  max-width: 60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  touch-action: none;
}


.footer {
  position: fixed;
  height: 28px;
  font-size: calc($font-size - 0.25rem);
  z-index: 20;
  touch-action: none;
}

main {
  padding-top: $navheight;
}

.welcome-logo {
  background: #006224;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 1rem;
}
.no-geo-dialog .btn-close {display: none;}
  
// these ensure that the ribbons scroll all the way
.ribbon-content {
  width: 100%;
  height: auto;
  touch-action: none;
  position: absolute;
  overscroll-behavior-x: contain;
}

.ribbons-container {
  height: fit-content;
  user-select: none;
  touch-action: none;
}


.landing-card {
  margin: 0.2rem;
  padding: 0rem;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.editor-card {
  max-width: "30rem";
}

.activity-editor-card {
  margin: 0.2rem;
  padding: 0rem;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}

.card-header.compact-card-header {
  padding: 5px;
}

.compact-card-header .card-title {
  margin-bottom: 0px;
}

.card-header.very-compact-card-header {
  padding: 0px;
}

.very-compact-card-header .card-title {
  margin-top: 5px;
}

.card-body.card-body-collapsed {
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
  height: 0px;
  opacity: 0;
  transition: opacity 0.3s ease, padding-top 0.3s ease, padding-bottom 0.3s ease, height 0.3s ease;
}

.card-body-expanded {
  box-sizing: border-box;
  height: auto; 
  overflow: visible;
  opacity: 1;
  transition: opacity 0.3s ease, padding-top 0.3s ease, padding-bottom 0.3s ease, height 0.3s ease;
}


// for more info: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
#mapcontainer {
  /* Fallback for browsers that do not support Custom Properties */
  height: calc(100vh - $navheight);
  height: calc(var(--vh, 1vh) * 100 - $navheight);
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profile-image {
  border-radius: 50%;
  height: calc($navheight * 0.7);
}

.settings-item {
  cursor: pointer;
}

.dayaxis {
  font-size: 20px;
}

.mini-plot-day-text {
  text-align: center;
  font-size: 16px;
}

.extra-day-label {
  font-size: 14px;
}

#dayaxis .tick {
  color: rgb(0, 255, 0);
}

#yaxis {
  font-size: 16px;
}

.timeaxis {
  font-size: 14px;
}

.delete {
  float: right;
}

.grid line {
  shape-rendering: crispEdges;
  stroke-opacity: 0.8;
  stroke: #eee;
}

.grid path {
  stroke-width: 0;
}

.outline {
  stroke-width: 1;
  stroke: black;
  fill: none;
}

.nowline {
  stroke-width: 1;
  fill: #f021126a;
}

.night {
  fill: #afafaf;
  opacity: 0.4;
}

.darknight {
  fill: #00000029;
}

/* These need to be above the line, marker, etc. */
.activity {
  background: #404040;
  color: white; // for legend font
  fill: #404040;
  stroke: #404040;
}

.temp {
  background: #eb4b41;
  color: white; // for legend font
  fill: #eb4b41;
  stroke: #eb4b41;
}

.wetbulb {
  background: #973304;
  color: white; // for legend font
  fill: #973304;
  stroke: #973304;
}


.stullwetbulb {
  background: #d57143;
  color: white; // for legend font
  fill: #d57143;
  stroke: #d57143;
}


.windchill {
  background: #000099;
  color: white; // for legend font
  fill: #000099;
  stroke: #000099;
}

.atemp {
  background: #000099;
  color: white; // for legend font
  fill: #000099;
  stroke: #000099;
}

.dewpoint {
  background: #009900;
  color: white; // for legend font
  fill: #009900;
  stroke: #009900;
}

.heatindex {
  background: #daa520;
  color: black; // for legend font
  fill: #daa520;
  stroke: #daa520;
}

.windsustained {
  background: #991199;
  color: white; // for legend font
  fill: #991199;
  stroke: #991199;
}

.windgust {
  background: #000066;
  color: white; // for legend font
  fill: #000066;
  stroke: #000066;
}

.humidity {
  background: #336622;
  color: white; // for legend font
  fill: #336622;
  stroke: #336622;
}

.precip {
  background: #996644;
  color: white; // for legend font
  fill: #996644;
  stroke: #996644;
}

.qpf {
  background: #5457b1;
  color: white; // for legend font
  fill: #5457b1;
  stroke: #5457b1;
}

.qpf-total {
  background: white;
  color: black;
  fill: white;
  stroke: #5457b1;
}
.qpf-total-text {
  color: #5457b1;
  font-size: 16px;

}

.cloud {
  background: #0000cc;
  color: white; // for legend font
  fill: #0000cc;
  stroke: #0000cc;
}

.recentsnow {
  background: #3300ffac;
  color: white;
  fill: #3300ffac;
  stroke: #3300ffac;
}

.recentsnowpath {
  stroke: #4397c7;
}

.snowfall {
  background: #7e028b;
  color: white;
  fill: #7e028b;
  stroke: #7e028b;
}

.snowfall-total {
  background: white;
  color: black;
  fill: white;
  stroke: #7e028b;
}
.snowfall-total-text {
  color: #7e028b;
  font-size: 16px;

}


.snow24 {
  background: #b300ff8c;
  color: black;
  fill: #b300ff8c;
  stroke: #b300ff8c;
}


.snow48 {
  background: #6600ff8c;
  color: black;
  fill: #6600ff8c;
  stroke: #6600ff8c;
}


.snow72 {
  background: #3300ff8c;
  color: black;
  fill: #3300ff8c;
  stroke: #3300ff8c;
}

.freezing_level_height {
  color: black;
  background: #ff9d00;
  fill: #ff9d00;
  stroke: #ff9d00;
}



.sun {
  color: black;
  background: #f9d133;
  fill: #f9d133;
  stroke: #f9d133;
}

.moon {
  color: black;
  background: #33d1f9;
  fill: #33d1f9;
  stroke: #33d1f9;
}

.horizon {
  color: black;
  background: #333333;
  fill: #333333;
  stroke: #333333;
}


.jupiter {
  color: black;
  background: #BFA77F22; /* Jupiter's color with transparency */
  fill: #BFA77F22;
  stroke: #BFA77F22;
}

.saturn {
  color: black;
  background: #F4C54222; /* Saturn's color with transparency */
  fill: #F4C54222;
  stroke: #F4C54222;
}

.mars {
  color: black;
  background: #D14B2822; /* Mars's color with transparency */
  fill: #D14B2822;
  stroke: #D14B2822;
}

.venus {
  color: black;
  background: #E3C27F22; /* Venus's color with transparency */
  fill: #E3C27F22;
  stroke: #E3C27F22;
}

.mercury {
  color: black;
  background: #ABA9A022; /* Mercury's color with transparency */
  fill: #ABA9A022;
  stroke: #ABA9A022;
}

.uranus {
  color: black;
  background: #88D0E822; /* Uranus's color with transparency */
  fill: #88D0E822;
  stroke: #88D0E822;
}

.neptune {
  color: black;
  background: #294C6022; /* Neptune's color with transparency */
  fill: #294C6022;
  stroke: #294C6022;
}

.pluto {
  color: black;
  background: #f9d13322; /* Pluto's color with transparency */
  fill: #f9d13322;
  stroke: #f9d13322;
}


.marker-text.freezing_level_height {
  stroke: #444;
  font-weight: 300;

}

.waveheight {
  color: white;
  background: #4b1591;
  fill: #4b1591;
  stroke: #4b1591;
}
.windwaveheight {
  color: white;
  background: #7f6285;
  fill: #7f6285;
  stroke: #7f6285;
}

.waveperiod2 {
  color: white;
  background: #3f8ed4;
  fill: #3f8ed4;
  stroke: #3f8ed4;
}
.waveperiod {
  color: white;
  background: #0c487c;
  fill: #0c487c;
  stroke: #0c487c;
}

.tide {
  color: white;
  background: #781da2;
  fill: #781da2;
  stroke: #781da2;
}

// .rain {
//   background: #666666;
//   color: white; // for legend font
//   fill: #666666;
//   stroke: #666666;
// }

// .thunderstorms {
//   background: #ffd500;
//   color: #000;
//   fill: #ffd500;
//   stroke: #ffd500;
// }

// .snow {
//   background: #4397c7;
//   color: black; // for legend font
//   fill: #4397c7;
//   stroke: #4397c7;
// }

// .freezingrain {
//   background: #c49bc9;
//   color: black; // for legend font
//   fill: #c49bc9;
//   stroke: #c49bc9;
// }

// .sleet {
//   background: #df6e2b;
//   color: black; // for legend font
//   fill: #df6e2b;
//   stroke: #df6e2b;
// }

.legend {
  stroke: #000;
}

.legend-text {
  box-shadow: 1px 1px 2px black;
  font-size: 1.4em;
  font-weight: 500;
  margin: 0.1em;
  opacity: 80%;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.legend-place {
  background-color: white;
}

.legend-text a {
  color: white;
}



.legend-text a:hover {
  color: #dddddd;
}

.freezeline {
  stroke: rgb(36, 13, 121, 0.4);
  stroke-width: 2;
  // stroke-dasharray: 5, 10;
}

.elevationline {
  stroke: rgba(23, 79, 6, 0.353);
  stroke-width: 2;  
}

.elevationline-text {
  fill:  rgba(23, 79, 6, 0.353);
}
.midnight-line {
  stroke: rgb(0, 0, 0, 0.3);
  stroke-width: 2;
  stroke-dasharray: 5, 5;
}

.line {
  fill: none;
  stroke-width: 2;
}

.marker-text {
  font-size: 16px;
  font-weight: 400;
  stroke: none;
}

.marker {
  fill: #000;
  stroke: none;
}

.barb {
  fill: grey;
  stroke-width: 0.9px;
  stroke: grey;
}

#location-string {
  font-size: smaller;
  padding: 0 10px 2px 5px;
  text-align: left;
  z-index: -2;
  width: inherit;
}

#location-string a {
  color: #006224;
}

#welcome-banner {
  background: #dddddd;
  border-color: #006224;
  border-radius: 10px;
  border: 2px;
  color: black;
  margin-bottom: 10px;
  padding: 1em;
}

#welcome-banner li {
  text-align: left;
}

#welcome-banner h1 {
  font-size: larger;
}

#welcome-banner p {
  font-size: smaller;
}

.Paired .q0-12 {
  fill: rgb(166, 206, 227);
}

.Paired .q1-12 {
  fill: rgb(31, 120, 180);
}

.Paired .q2-12 {
  fill: rgb(178, 223, 138);
}

.Paired .q3-12 {
  fill: rgb(51, 160, 44);
}

.Paired .q4-12 {
  fill: rgb(251, 154, 153);
}

.Paired .q5-12 {
  fill: rgb(227, 26, 28);
}

.Paired .q6-12 {
  fill: rgb(253, 191, 111);
}

.Paired .q7-12 {
  fill: rgb(255, 127, 0);
}

.Paired .q8-12 {
  fill: rgb(202, 178, 214);
}

.Paired .q9-12 {
  fill: rgb(106, 61, 154);
}

.Paired .q10-12 {
  fill: rgb(255, 255, 153);
}

.Paired .q11-12 {
  fill: rgb(177, 89, 40);
}

.floatlegend {
  font-size: 0.8em;
  height: fit-content;
  left: 0;
  margin-top: 0px;
  margin-left: 5px;
  width: fit-content;
}

.ribbon {
  height: fit-content;
  padding-top: 0px;
}


// a giant bar to mask out behind the top timer ribbon
#top-blocker {
  background-color: #fafafa;
  width: 200%;
  position: absolute;
  top: 0px;
  left: -500px;
  z-index: -1;
}

.timeribbon {
  height: fit-content;
  width: fit-content;
  z-index: 1;
}

#root {
  height: 100%;
}

.leaflet-top {
  top: 30px;
}

.leaflet-marker-white {
  filter: hue-rotate(120deg);
}

.warning {
  color: red;
}


.leaflet-control-geosearch form input {
  font-size: 16px !important;
}

// .leaflet-right .leaflet-control-geosearch form {
//   left: -305px !important;
// }

// .leaflet-popup-pane {
//   pointer-events: none;
// }

.loading {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 80%;
  min-height: 80px;
}
.loading div {
  display: inline-block;
  position: absolute;
  left: 0px;
  width: 2px;

  animation: loading 1.1s cubic-bezier(0.5, 0.5, 0.5, 1) infinite;
}
.loading div:nth-child(1) {
  left: 5%;
  animation-delay: -1s;
}
.loading div:nth-child(2) {
  left: 20%;
  animation-delay: -2s;
}
.loading div:nth-child(3) {
  left: 35%;
  animation-delay: -3s;
}
.loading div:nth-child(4) {
  left: 50%;
  animation-delay: -4s;
}
.loading div:nth-child(5) {
  left: 65%;
  animation-delay: -5s;
}
.loading div:nth-child(6) {
  left: 80%;
  animation-delay: -6s;
}
.loading div:nth-child(7) {
  left: 95%;
  animation-delay: -7s;
}
@keyframes loading {
  0%,
  100% {
    background: #a1a1a1;
    top: 8px;
    height: 40px;
  }
  25% {
    top: 45px;
    height: 0px;
  }
  50% {
    background: #626262;
    top: 8px;
    height: 40px;
  }
  75% {
    top: 0px;
    height: 0px;
  }
}

.ghost {
  background: lightblue !important;
}


@function get-contrast-color($color) {
  $r: red($color);
  $g: green($color);
  $b: blue($color);

  $luminance: (0.299 * $r + 0.587 * $g + 0.114 * $b);

  @if ($luminance > 127.5) {
    @return #000;
  } @else {
    @return #fff;
  }
}

@each $key, $value in $weatherColors {
  .#{$key} {
    background: $value;
    color: get-contrast-color($value);
    fill: $value;
    stroke: $value;
  }
}


.pricing-table {
  width: 100%;
  border-collapse: collapse;
}

.pricing-table th,
.pricing-table td {
  padding: 12px 15px;
  text-align: center;
}

.pricing-table th {
  background-color: #f0f0f0;
}

.pricing-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.pricing-table .hover:hover  {
  background-color: #feffde;
  cursor: pointer;
}


/* Rounded corners */
.pricing-table {
  border-radius: 10px;
  overflow: hidden;
}

/* Box-shadow for depth effect */
.pricing-table {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.page-editor-rows {
  margin: 5px;
}